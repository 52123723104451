/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-12-04 16:28:50
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-04 16:39:06
 */
import Vue from 'vue'
const proxy = Vue.prototype


// 获取用户信息
export const getUserInfo = async (params) => {
  const [res, err] = await proxy.$get('getUserInfo', params);
  return [res, err]
}