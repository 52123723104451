<!--
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-21 10:38:17
 * @LastEditors: YGQ
 * @LastEditTime: 2024-04-22 10:45:05
 * @FilePath: /oneid/src/components/Header.vue
 * @Description: 页头顶部栏
-->
<template>
  <div class="bar flex-between row-center">
    <!-- <div style="opacity: 0.2">
      <div class="font26" style="font-weight: 900">ONEID</div>
      <div class="font10">杰姆龙用户中心</div>
    </div> -->

    <!-- 退出按钮 -->
    <div class="logout" @click="logout"><i class="el-icon-switch-button" style="margin-right: 4px" /> 退出</div>

    <!-- 用户头像与姓名 -->
    <div class="user flex bg-N7 text-left" @click="navigate('user')">
      <el-avatar :size="48" :src="user.avatar"> {{ user.name }}</el-avatar>
      <div class="font12 N3 one-line" style="margin: 4px 0 0 8px; width: 165px">
        <div class="font14 N1 line24 bold one-line">{{ user.name }}</div>
        <div>{{ user.hotel?.length == 1 ? user.hotel[0].name : user.companyName }}</div>
      </div>
    </div>
  </div>
</template>

<script> 
export default {
  data () {
    return {
      user: {},
    }
  },
  mounted () {
    const user = localStorage.getItem('user')
    if (!user) return this.logout()
    this.user = JSON.parse(user)
  },
  methods: {
    async navigate (command) {
      const { path } = this.$route
      if (command == 'user' && path != '/user') {
        this.$router.push(command) // 用户信息
      } else if (command == 'user' && path == '/user') {
        this.$router.go(-1) // 回退
      } else if (command == 'logout') {
        this.logout() // 登出
      }
    },

    // 退出登录
    async logout () {
      const [, err] = await this.$post('logout')
      if (err) return this.$msg(err)
      localStorage.removeItem('user');
      localStorage.removeItem('geemro_hotel');
      this.$cookie.remove('TOKEN')
      this.$router.replace('/');
    }
  }
};
</script>

<style scoped>
.bar {
  padding: 0 24px 0 48px;
  width: 100%;
  height: 72px;
  box-sizing: border-box;
}
.logout {
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
}
.logout:hover {
  opacity: 1;
  color: #f56c6c;
  transform: scale(1.05);
  transition: all 0.3s;
}
.user {
  width: 230px;
  height: 48px;
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.user:hover {
  background-color: #ebedf0;
  transition: all 0.3s;
}
</style>