<!--
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2023-04-14 11:48:28
 * @LastEditors: 刘康宁 869161591@qq.com
 * @LastEditTime: 2023-04-18 11:37:37
 * @FilePath: /oneid/src/views/home/components/DialogPassword.vue
 * @Description: 修改密码弹框
-->
<template>
  <el-dialog
    title="修改密码"
    :visible="visible"
    width="440px"
    custom-class="dialog"
    :before-close="() => $emit('hide')"
    :show-close="closable"
    :close-on-click-modal="closable"
    :close-on-press-escape="closable"
  >
    <div style="padding: 0 16px 16px">
      <el-alert show-icon title="为保证账号安全, 请定期更换密码。" type="warning" :closable="false" />
      <el-form ref="form" :model="form" :rules="rules" style="margin-top: 20px">
        <el-form-item prop="password">
          <el-input
            placeholder="请输入新密码"
            v-model="form.password"
            type="password"
            show-password
            maxlength="32"
            autocomplete="new-password"
          />
        </el-form-item>
        <el-form-item prop="passwordCopy">
          <el-input
            placeholder="请再次输入新密码"
            v-model="form.passwordCopy"
            type="password"
            show-password
            maxlength="32"
            autocomplete="new-password"
            @keyup.enter.native="onOk"
          />
        </el-form-item>
        <el-button class="button" type="primary" @click="onSubmit" :loading="isLoading">
          {{ isLoading ? '正在提交' : '确 定' }}
        </el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    closable: Boolean
  },
  data () {
    var passwordCopyValidator = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'));
      } else if (value != this.form.password) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };
    return {
      isLoading: false, // 提交加载态
      form: {
        password: '', // 新密码
        passwordCopy: '' // 再次输入新密码
      },
      rules: {
        password: this.$getFormRule({ name: '新密码', min: 6, minMsg: '密码不能少于 6 位' }),
        passwordCopy: this.$getFormRule({ name: '新密码', validator: passwordCopyValidator }),
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs['form'].validate(async (valid) => {
        if (!valid) {
          return false;
        } else {
          const timestamp = new Date().getTime()
          const password = this.$getRsaCode(timestamp + this.form.password) // 使用公钥加密密码
          this.isLoading = true
          const [res, err] = await this.$post('resetPassword', { password, timestamp }) // 请求账密登录
          this.isLoading = false
          if (err) return this.$msg(err)
          this.onHide()
          this.$msg.success('修改成功')
          const user = JSON.parse(localStorage.user)
          user.passwordModifyTime = res.passwordModifyTime // 更新用户信息中的密码修改时间
          localStorage.setItem('user', JSON.stringify(user))
        }
      });
    },

    // 隐藏弹框
    onHide () {
      this.$emit('hide')
    }
  }
}
</script>

<style scoped>
.button {
  width: 100%;
}
</style>

<style >
.dialog {
  border-radius: 8px;
}
.el-dialog__body {
  padding: 16px 16px 24px;
}
</style>