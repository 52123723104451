<!--
 * @Description: 个人信息-》绑定微信
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-12-04 15:52:09
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-05 10:42:20
-->
<template>
  <el-dialog
    title="绑定微信"
    width="440px"
    custom-class="dialog"
    :visible="visible"
    :before-close="() => $emit('hide')"
  >
    <div style="padding: 0 16px 16px; min-height: 370px">
      <!-- 微信登录二维码 -->
      <LoginWeChat
        v-if="visible"
        platform="wechat"
        text1="使用微信扫一扫"
        text2="绑定微信"
        api="getThirdPartyBindCode"
        @ok="onBindSuccess"
      />
    </div>
  </el-dialog>
</template>

<script>
import LoginWeChat from "@/components/LoginWeChat";
export default {
  components: { LoginWeChat },
  props: {
    visible: Boolean,
  },
  methods: {
    // 扫码成功
    onBindSuccess() {
      const user = JSON.parse(localStorage.getItem("user"));
      user.isBindWx = 1;
      localStorage.setItem("user", JSON.stringify(user));
      this.$msg.success("绑定成功");
      this.$emit("update");
      this.$emit("hide");
    },
  },
};
</script>
 
<style >
.dialog {
  border-radius: 8px;
}
</style>