<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-12-04 10:16:56
 * @LastEditors: YGQ
 * @LastEditTime: 2024-03-13 16:18:10
-->
<template>
  <div style="position: relative">
    <div v-if="!isLoading">
      <div class="code-box">
        <img :src="codeUrl" class="code-img" />
      </div>
      <div class="code-info font14">
        <p>{{ text1 }}</p>
        <p>{{ text2 }}</p>
      </div>
    </div>
    <div v-else v-loading="isLoading" style="height: 360px"></div>
    <div class="code-overlay flex-centered" v-if="isExpire">
      <div class="overlay-text font24">
        <p>登录超时</p>
        <p>二维码失效</p>
        <el-button
          class="refresh-button button"
          type="primary"
          @click="getThirdPartyLoginCode"
          >刷 新</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { createSocket, setUid, closeSocket } from "@/api/socket.js";
import { getUserInfo } from "@/api/dataService";
export default {
  name: "LoginScanCode",
  props: {
    platform: String, // 平台：微信(wechat)、钉钉
    api: {
      type: String,
      default: "getThirdPartyLoginCode",
    },
    text1: {
      type: String,
      default: "使用微信扫一扫",
    },
    text2: {
      type: String,
      default: "登录用户中心",
    },
  },
  data() {
    return {
      isLoading: false,
      countdown: "",
      timer: null, //计时器
      isExpire: false, // 是否过期
      codeUrl: "", // 二维码图片链接
    };
  },
  created() {
    // 获取第三方登录的二维码图片
    this.getThirdPartyLoginCode();
  },
  beforeDestroy() {
    window.removeEventListener("onMessageWS", this.websocketMsg);
    closeSocket(); // 关闭socket连接
  },
  methods: {
    // 获取第三方登录二维码
    async getThirdPartyLoginCode() {
      this.isLoading = true;
      const [res, err] = await this.$get(this.api, {
        platform: this.platform,
      });
      if (err) return this.$msg(err);
      this.codeUrl = res.url;
      this.initSocket(res); // 初始化socket
      this.startCountdown(5 * 60); // 开始倒计时
      this.isLoading = false;
    },
    // 获取用户信息
    async getUserInfo() {
      const [user, err] = await getUserInfo();
      this.$emit("ok", user);
    },
    // 初始化socket
    initSocket(data) {
      setUid(data.uuid); // 设置用于通信的uuid
      createSocket(); // 建立socket连接
      window.removeEventListener("onMessageWS", this.websocketMsg);
      window.addEventListener("onMessageWS", this.websocketMsg);
    },
    // websocket消息通知
    websocketMsg(event) {
      const data = event.detail;
      switch (data.event) {
        case "onLoginCodeScan": // 第三方账号登录结果
          if (data.data.is_login == 2) return this.$msg.warning(data.data.msg);
          this.$cookie.set("TOKEN", data.data.token);
          this.getUserInfo();
          break;
        case "onBindCodeScan": // 第三方账号绑定结果
          if (data.data.is_bind == 2) return this.$msg.warning(data.data.msg);
          this.$emit("ok");
          break;
        default:
          break;
      }
    },
    // 关闭soket连接
    closeSocket() {
      closeSocket(); // 关闭soket连接
    },
    // 开始倒计时
    startCountdown(seconds) {
      this.countdown = seconds;
      this.isExpire = false;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.isExpire = true;
          clearInterval(this.timer); // 倒计时结束时清除计时器
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/login-form.scss";

.code-box {
  width: 288.6px;
  height: 288.6px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #d7d9dc;
}
.code-box .code-img {
  width: 100%;
  height: 100%;
}
.code-info {
  line-height: 1.4;
  margin-top: 12px;
}
.code-overlay {
  width: 288.6px;
  height: 288.6px;
  position: absolute;
  left: 50%;
  top: 42.5%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay-text {
  line-height: 1.4;
  color: #fff;
  font-weight: bold;
}
.refresh-button {
  margin-top: 16px;
  width: 160px;
}
</style>