<!--
 * @Description: 个人信息-》绑定微信
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-12-04 15:52:09
 * @LastEditors: YGQ
 * @LastEditTime: 2024-03-13 16:42:32
-->
<template>
  <el-dialog
    title="绑定钉钉"
    width="440px"
    custom-class="dialog"
    :visible="visible"
    :before-close="() => $emit('hide')"
  >
    <div style="padding: 0 16px 16px; min-height: 370px">
      <!-- 微信登录二维码 -->
      <LoginDingtalk
        v-if="visible"
        @ok="onBindSuccess"
        type="bind"
        text1="使用钉钉扫一扫"
        text2="绑定钉钉"
      />
    </div>
  </el-dialog>
</template>

<script>
import LoginDingtalk from "@/components/LoginDingtalk";
export default {
  components: { LoginDingtalk },
  props: {
    visible: Boolean,
  },
  methods: {
    // 扫码成功
    onBindSuccess() {
      const user = JSON.parse(localStorage.getItem("user"));
      user.isBindDingtalk = 1;
      localStorage.setItem("user", JSON.stringify(user));
      this.$msg.success("绑定成功");
      this.$emit("update");
      this.$emit("hide");
    },
  },
};
</script>
 
<style >
.dialog {
  border-radius: 8px;
}
</style>