/*
 * @Description: websocket
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2022-12-26 18:41:14
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-05 10:38:58
 */
import host from '../api/host'

// socket的连接地址
const WEBSOCKET_URL = {
  release: 'wss://pms.geemro.com/wss', // 正式版默认接口地址
  trial: "wss://trial.geemro.com/wss", // 体验版接口地址
  develop: 'wss://dev.geemro.com/wss', // 开发版接口地址
  local: 'wss://dev.geemro.com/wss', // 本地版接口地址
}

// socket的四种状态
const WebSocketState = {
  OPEN: 0,// 连接已经建立并且可以进行通信
  CONNECTING: 1,// 连接正在建立
  CLOSING: 2,// 连接正在关闭
  CLOSED: 3,// 连接已经关闭或无法建立
}
// socket非正常关闭的错误原因
const WebSocketErrorReason = {
  1000: '正常关闭',
  1001: 'WebSocket连接被客户端关闭',
  1006: 'WebSocket连接异常关闭，无法连接服务器',
}

const MAX_RECONNECT_ATTEMPTS = 5; // 最大重连尝试次数
let reconnectAttempts = 0; // 当前重连尝试次数


const BASE_API = WEBSOCKET_URL[host.getHost().env];

// 存储全局变量
let Socket = null // Socket
let setIntervalWesocketPush = null // 发送心跳定时器
let uuid = null // 用于通信的uuid

// 设置用于sockeet通信的uid
export const setUid = (id) => {
  if (id) return uuid = id
  return uuid = new Date().getTime()
}

/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
export const createSocket = (url = BASE_API) => {
  Socket && Socket.close()
  if (!Socket) {
    console.log('建立websocket连接-', url)
    Socket = new WebSocket(url)
    Socket.onopen = onopenWS // 监听打开socket之后的动作
    Socket.onmessage = onmessageWS // 监听socket通信的动作
    Socket.onerror = onerrorWS // 监听socket连接失败的动作
    Socket.onclose = oncloseWS /// 监听socket关闭的动作
  } else {
    console.log('websocket已连接')
  }
  return Socket
}

/*
  *手动关闭socket
 */
export const closeSocket = () => {
  Socket && Socket.close() && clearInterval(setIntervalWesocketPush)
}

/**打开WS之后发送心跳 */
const onopenWS = () => {
  reconnectAttempts = 0 //重置重连次数 
  Socket.send(uuid)
  sendPing()
}

/**连接失败重连 */
const onerrorWS = () => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)
  console.log('连接失败,自动重连中')
  if (Socket.readyState !== WebSocketState.CLOSED) {
    Socket = null
    createSocket(BASE_API)
  }
}

/**WS数据接收统一处理 */
const onmessageWS = e => {
  if (e.data == '[]') return
  let data = {}
  try {
    data = JSON.parse(e.data)
  } catch (error) { data = {} }
  window.dispatchEvent(new CustomEvent('onMessageWS', {
    detail: data
  }))
}

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = message => {
  setTimeout(() => {
    if (Socket.readyState === WebSocketState.CONNECTING) {
      connecting(message)
    } else {
      Socket.send(JSON.stringify(message))
    }
  }, 1000)
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
  if (Socket !== null && Socket.readyState === WebSocketState.CLOSED) {
    Socket.close()
    createSocket(BASE_API)
  } else if (Socket.readyState === WebSocketState.OPEN) {
    Socket.send(JSON.stringify(message))
  } else if (Socket.readyState === WebSocketState.CONNECTING) {
    connecting(message)
  }
}

/**断开重连 */
const oncloseWS = (event) => {
  console.log(event);
  // 正常关闭socket：一般手动关闭(手动调用Socket.close())
  if (event.wasClean) {
    Socket.onmessage = null // 停止监听消息
    Socket = null
    reconnectAttempts = 0 // 连接正常关闭，重置重连尝试次数
    clearInterval(setIntervalWesocketPush)
    console.log('websocket正常关闭')
  } else {// 其他异常原因导致socket断开 此时需要自动进行重连
    if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
      reconnectAttempts++;
      Socket = null
      createSocket()
      console.log(WebSocketErrorReason[event.code] || '其他原因');
      console.log(`尝试第${reconnectAttempts}次重连`)
    } else {
      console.log(`已达最大次重连次数`)
    }
  }
}

/**发送心跳
 * @param {} time 心跳间隔毫秒 默认5000
 * @param {} ping 心跳名称 默认字符串ping
 */
export const sendPing = (time = 30 * 1000, ping = 'ping') => {
  clearInterval(setIntervalWesocketPush)
  Socket.send(uuid)
  setIntervalWesocketPush = setInterval(() => {
    Socket.send(uuid)
  }, time)
}