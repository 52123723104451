<template>
  <!-- 用户中心页 /user -->
  <div>
    <img class="bg" src="@/assets/dashboard/bg.webp" />
    <Header />
    <div class="content">
      <TitleBar title="个人信息" />
      <!-- 基础信息 -->
      <div class="card flex-between">
        <el-descriptions title="基础信息" :column="4">
          <el-descriptions-item label="姓名">{{
            user.name
          }}</el-descriptions-item>
          <el-descriptions-item label="工号">{{
            user.code
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{
            user.gender == 1 ? "男" : user.gender == 2 ? "女" : "未知"
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">
            <span> {{ user.phone }} </span>
            <el-button
              type="text"
              style="padding: 0 8px"
              @click="dialogName = 'phone'"
            >
              <i class="el-icon-edit" />
            </el-button>
          </el-descriptions-item>
          <el-descriptions-item label="企业" :span="1"
            >{{ user.companyName }}
          </el-descriptions-item>
          <el-descriptions-item label="岗位" :span="3">
            <el-tag
              v-for="job in user.job"
              :key="job.name"
              type="info"
              size="mini"
              style="margin-right: 8px"
              >{{ job.name }}</el-tag
            >
          </el-descriptions-item>
        </el-descriptions>
        <div class="avatar" @click="dialogName = 'avatar'">
          <div v-if="!user.avatar" class="name">
            {{ user.name ? user.name.slice(-2) : "" }}
          </div>
          <img v-else :src="user.avatar" />
        </div>
      </div>
      <!-- 账号安全 -->
      <div class="card">
        <div class="N1 font16 bold">账号安全</div>
        <div class="flex">
          <div class="app-action active" :class="{ gray: user.isBindWx != 1 }">
            <div
              class="app-icon wechat"
              @click="onClickBindBtn('wechat', 'isBindWx')"
            />
            <div class="G1 font12">
              {{ user.isBindWx == 1 ? "已绑定微信" : "绑定微信" }}
            </div>
          </div>
          <div
            class="app-action active"
            :class="{ gray: user.isBindDingtalk != 1 }"
          >
            <div
              class="app-icon dingtalk"
              @click="onClickBindBtn('dingtalk', 'isBindDingtalk')"
            />
            <div class="blue font12">
              {{ user.isBindDingtalk == 1 ? "已绑定钉钉" : "绑定钉钉" }}
            </div>
          </div>
          <div class="app-action gray">
            <div class="app-icon alipay" />
            <div class="blue font12">未开通</div>
          </div>
          <div class="app-action active" @click="dialogName = 'password'">
            <img class="pwd-icon" src="@/assets/login/password.png" />
            <div class="blue font12">修改密码</div>
          </div>
        </div>
        <!-- <el-button type="text" @click="dialogName = 'password'"> 修改密码 </el-button> -->
      </div>
      <!-- 门店信息 -->
      <div class="card">
        <div class="N1 font16 bold">门店信息</div>
        <div class="padding8"> </div>
        <el-table
          :data="user.hotel"
          stripe
          :header-cell-style="{ background: '#f2f3f5' }"
        >
          <el-table-column prop="name" label="门店名称" width="350" />
          <el-table-column prop="code" label="编号"> </el-table-column>
          <el-table-column prop="areaName" label="区域"> </el-table-column>
          <el-table-column prop="brandName" label="品牌"> </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 100" size="small" type="success"
                >营业中</el-tag
              >
              <el-tag v-else-if="scope.row.state == 110" size="small"
                >未开业</el-tag
              >
              <el-tag
                v-else-if="scope.row.state == 150"
                size="small"
                type="warning"
                >暂停营业</el-tag
              >
              <el-tag
                v-else-if="scope.row.state == 200"
                size="small"
                type="info"
                >系统已过期</el-tag
              >
              <el-tag
                v-else-if="scope.row.state == 250"
                size="small"
                type="danger"
                >已停业</el-tag
              >
              <el-tag v-else type="info" size="small">异常</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="padding16"> </div>
    <!-- 修改手机号弹框 -->
    <DialogPhone :visible="dialogName == 'phone'" @hide="dialogName = null" />
    <!-- 修改头像弹框 -->
    <DialogAvatar
      :visible="dialogName == 'avatar'"
      @hide="dialogName = null"
      @update="onAvatarChange"
    />
    <!-- 修改密码弹框 -->
    <DialogPassword
      :visible="dialogName == 'password'"
      @hide="dialogName = null"
    />
    <!-- 绑定微信弹框 -->
    <DialogWeChatScan
      :visible="dialogName == 'wechat'"
      @hide="dialogName = null"
      @update="onBindSuccess('isBindWx')"
    />
    <!-- 绑定钉钉弹框 -->
    <DialogDingtalkScan
      :visible="dialogName == 'dingtalk'"
      @hide="dialogName = null"
      @update="onBindSuccess('isBindDingtalk')"
    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleBar from "@/components/TitleBar";
import DialogPhone from "./components/DialogPhone";
import DialogPassword from "./components/DialogPassword";
import DialogAvatar from "./components/DialogAvatar";
import DialogWeChatScan from "./components/DialogWeChatScan";
import DialogDingtalkScan from "./components/DialogDingtalkScan";
export default {
  components: {
    Header,
    TitleBar,
    DialogPhone,
    DialogPassword,
    DialogWeChatScan,
    DialogAvatar,
    DialogDingtalkScan,
  },
  data() {
    return {
      dialogName: null,
      user: JSON.parse(localStorage.user),
    };
  },
  methods: {
    onAvatarChange(img) {
      this.user.avatar = img;
      localStorage.setItem("user", JSON.stringify(this.user));
    },

    // 点击"微信扫码登录"或"已绑定微信"按钮
    onClickBindBtn(platform, key) {
      const bindObj = { wechat: "微信", dingtalk: "钉钉" };
      if (this.user[key] != 1) return (this.dialogName = platform);
      this.$msgbox
        .confirm(`您已经绑定过${bindObj[platform]}账号。`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "解绑",
          type: "success",
        })
        .then()
        .catch(() => this.unbindThirdPartyAccount(platform, key));
    },

    // 解绑微信/钉钉
    async unbindThirdPartyAccount(platform, key) {
      const [res, err] = await this.$post("unbindThirdPartyAccount", {
        platform,
      });
      if (err) return this.$msg(err);
      this.user[key] = 2;
      localStorage.setItem("user", JSON.stringify(this.user));
    },

    // 新绑定微信成功回调
    onBindSuccess(key) {
      this.user[key] = 1;
      localStorage.setItem("user", JSON.stringify(this.user));
    },
  },
};
</script>

<style scoped>
@import "../../styles/dashboard-bg.scss";
.content {
  margin: 0 auto;
  padding: 0 24px;
  width: 1200px;
}
.card {
  background-color: #fff;
  text-align: left;
  margin: 24px 0;
  padding: 24px;
  border-radius: 8px;
}
.avatar {
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  font-size: 40px;
  text-align: center;
  color: #dcdee0;
  background-color: #ebedf0;
  overflow: hidden;
  margin-top: 40px;
  min-width: 96px;
  max-width: 96px;
  height: 96px;
  line-height: 96px;
}
.avatar .name {
  line-height: 104px;
}
.avatar img {
  width: 96px;
  height: 96px;
  object-fit: cover;
}
.avatar::after {
  z-index: 1;
  position: absolute;
  left: 0;
  content: "更换头像";
  opacity: 0;
  background: #00000055;
  text-align: center;
  width: 96px;
  line-height: 96px;
  font-size: 12px;
  font-weight: 800;
  color: #fff;
  transition: all 0.3s;
}
.avatar:hover::after {
  opacity: 1;
}
.app-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
  width: 80px;
  transition: all 0.2s;
}
.gray {
  filter: grayscale(1);
  opacity: 0.6;
}
.active:hover {
  cursor: pointer;
  filter: grayscale(0);
  opacity: 1;
}
.app-icon {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-image: url(https://gw.alipayobjects.com/mdn/prod_resou/afts/img/A*wLosTZsNyYcAAAAAAAAAAABjARQnAQ);
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  transition: all 0.2s;
  margin: 24px 0 12px;
  /* filter: grayscale(1); */
  /* opacity: 0.3; */
}
.wechat {
  background-position: 0 -119px;
}
.dingtalk {
  background-position: 0 -59.5px;
}
.pwd-icon {
  width: 56px;
  height: 56px;
  margin: 24px 0 12px;
}
</style>
