<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-12-04 10:16:56
 * @LastEditors: YGQ
 * @LastEditTime: 2024-03-13 18:22:01
-->
<template>
  <div style="position: relative">
    <div v-show="!isLoading">
      <div class="code-box">
        <div id="self_defined_element" class="self-defined-classname"></div>
      </div>
      <div class="code-info font14">
        <p>{{ text1 }}</p>
        <p>{{ text2 }}</p>
      </div>
    </div>
    <div v-show="isLoading" v-loading="isLoading" style="height: 360px"></div>
    <div class="code-overlay flex-centered" v-if="isExpire">
      <div class="overlay-text font24">
        <p>登录超时</p>
        <p>二维码失效</p>
        <el-button
          class="refresh-button button"
          type="primary"
          @click="createDingtalkLoginCode"
          >刷 新</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/dataService";
import host from "@/api/host";
export default {
  name: "LoginDingtalk",
  props: {
    type: {
      type: String,
      default: "login",
    },
    text1: {
      type: String,
      default: "使用钉钉扫一扫",
    },
    text2: {
      type: String,
      default: "登录用户中心",
    },
  },
  data() {
    return {
      isLoading: false,
      countdown: "",
      timer: null, //计时器
      isExpire: false, // 是否过期
      codeUrl: "", // 二维码图片链接
    };
  },
  mounted() {
    // 创建二维码
    this.createDingtalkLoginCode();
  },
  methods: {
    // 创建钉钉登录二维码
    createDingtalkLoginCode() {
      this.isLoading = true;
      const API = host.getHost().host + "loginByDingTalkScan";
      window.DTFrameLogin(
        {
          id: "self_defined_element",
          width: 280,
          height: 280,
        },
        {
          redirect_uri: API,
          client_id: "suitefdgkpv74vx2ciyfr",
          scope: "openid",
          response_type: "code",
          prompt: "consent",
        },
        this.handleMessage,
        (errorMsg) => alert(`Login Error: ${errorMsg}`)
      );
      setTimeout(() => {
        this.isLoading = false;
      }, 800);
    },
    // 处理钉钉扫码登录回调
    handleMessage(loginResult) {
      const { redirectUrl, authCode, state } = loginResult;
      this.type == "login" && this.loginByDingTalkScan(authCode);
      this.type == "bind" && this.bindByDingTalk(authCode);
    },
    // 钉钉扫码登录
    async loginByDingTalkScan(authCode) {
      const [res, err] = await this.$post("loginByDingTalkScan", { authCode });
      if (err) {
        this.$emit("cancel");
        return this.$msg(err);
      }
      this.$cookie.set("TOKEN", res.token);
      this.getUserInfo();
    },
    // 钉钉账号绑定
    async bindByDingTalk(authCode) {
      const [res, err] = await this.$post("bindByDingTalk", { authCode });
      if (err) return this.$msg(err);
      this.$emit("ok");
    },
    // 获取用户信息
    async getUserInfo() {
      const [user, err] = await getUserInfo();
      this.$emit("ok", user);
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/login-form.scss";

.code-box {
  width: 288.6px;
  height: 288.6px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #d7d9dc;
}
.code-box .code-img {
  width: 100%;
  height: 100%;
}
.code-info {
  line-height: 1.4;
  margin-top: 12px;
}
.code-overlay {
  width: 288.6px;
  height: 288.6px;
  position: absolute;
  left: 50%;
  top: 42.5%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay-text {
  line-height: 1.4;
  color: #fff;
  font-weight: bold;
}
.refresh-button {
  margin-top: 16px;
  width: 160px;
}

.self-defined-classname {
  width: 280;
  height: 280;
}
</style>